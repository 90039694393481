import * as D from 'io-ts/Decoder';

import { BooleanLiteralDecoder, StringOrNumberDecoder } from '../types';

export enum PaymentProcessor {
  Braintree = 'braintree',
  Fortis = 'fortis',
  Stripe = 'stripe',
  Authorize = 'authorize',
}

export enum PaymentOption {
  CreditCard = 'credit_card',
  ApplePay = 'apple_pay',
  GooglePay = 'google_pay',
  Insurance = 'insurance',
  // PayLater = 'pay_later',
  // BlackHawkVisaGiftCard = 'blackhawk_visa_gift_card',
}

export enum PaymentCollectionType {
  Vault = 'vault',
  OneTime = 'one_time',
}

export enum PaymentUsedFor {
  InHomeBooking = 'in-home-booking',
  AtWorkBooking = 'at-work-booking',
  Membership = 'membership',
  Gift = 'gift',
  Account = 'account',
  Store = 'store',
  SpaBooking = 'spa-booking',
}

export const SourcePaymentMethodDecoder = D.partial({
  id: StringOrNumberDecoder,
  token: D.nullable(D.string),
  braintree_payment_method_token: D.nullable(D.string),
  braintree_customer_profile_id: D.nullable(D.string),
  last_four: D.nullable(D.string),
  expired: D.boolean,
  expiration_year: D.nullable(D.string),
  expiration_month: D.nullable(D.string),
  postal_code: D.nullable(D.string),
  card_bin: D.nullable(D.string),
  profile_name: D.nullable(D.string),
  fname: D.nullable(D.string),
  lname: D.nullable(D.string),
  default: D.nullable(BooleanLiteralDecoder),
  status: D.nullable(D.string),
  card_type: D.nullable(D.string),
  card_type_abbreviation: D.nullable(D.string),
  dt_inserted: D.nullable(D.string),
  dt_edited: D.nullable(D.string),
  can_update: D.boolean,
  external_type: D.literal('braintree', 'fortis', 'stripe', 'authorize'),
  // Derived
  status_label: D.nullable(D.string),
  profile_name_full: D.nullable(D.string),
  expiration_label: D.nullable(D.string),
});

export type SourcePaymentMethodType = D.TypeOf<typeof SourcePaymentMethodDecoder>;
export const PaymentMethodDecoder = D.partial({
  id: D.string,
  token: D.nullable(D.string),
  braintreePaymentMethodToken: D.nullable(D.string),
  braintreeCustomProfileId: D.nullable(D.string),
  lastFourDigits: D.nullable(D.string),
  expired: D.boolean,
  expirationYear: D.nullable(D.string),
  expirationMonth: D.nullable(D.string),
  zipCode: D.nullable(D.string),
  cardBin: D.nullable(D.string),
  label: D.nullable(D.string),
  firstName: D.nullable(D.string),
  lastName: D.nullable(D.string),
  default: D.boolean,
  status: D.nullable(D.string),
  cardType: D.nullable(D.string),
  cardTypeAbbreviation: D.nullable(D.string),
  dateCreated: D.nullable(D.string),
  dateEdited: D.nullable(D.string),
  updatable: D.boolean,
  processor: D.literal('braintree', 'fortis', 'stripe', 'authorize'),
  // Derived
  statusLabel: D.nullable(D.string),
  labelFull: D.nullable(D.string),
  expirationLabel: D.nullable(D.string),
});
export type PaymentMethodType = D.TypeOf<typeof PaymentMethodDecoder>;

export type PaymentOptionsResponse = Record<
  PaymentOption,
  {
    available: boolean;
    webview_available: boolean;
    include_vaulted?: boolean;
    person_id?: number;
    recommended_collection_type: PaymentCollectionType;
    processors: PaymentProcessor[];
    vaulted?: SourcePaymentMethodType[];
  }
>;
