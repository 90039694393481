import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { constants, helpers } from 'utils';
import { deleteCrossParams, getCrossParams, setCrossParams } from 'utils/helpers';

import { createTransform } from 'redux-persist';
import { getTokenCookie } from 'redux/apis/OG/auth';
import {
  BookingCategoryEnum,
  BookingConfigurationType,
  BookingCreationPayloadType,
  BookingType,
  GenderRequestedEnum,
  ProviderAvailabilityType,
  ProviderType,
  SERVICES_BY_ID,
  ServiceIds,
} from 'redux/apis/OG/booking';
import { AddressType, MembershipPlans } from 'redux/apis/OG/user';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import { PaymentProcessor } from '../../apis/OG/payment';
import persistReducer from '../../persistReducer';
import { authSlice } from '../auth';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Service = 'service',
  Details = 'details',
  Location = 'location',
  Time = 'time',
  Pricing = 'pricing',
  Summary = 'summary',
  SummaryAutoSubmit = 'summary-autosubmit',
  CreateAccount = 'create-account',
  IdConfirmation = 'id-confirmation',
  Jumio = 'jumio',
  Finish = 'finish',
}

export type BookingFlowParams = {
  presetAddress?: {
    zipCode: string;
    address: string;
    addressLong: string;
    raw?: any;
  } | null;
  presetModality?: ServiceIds | null;
  zipCode?: string | null;
};

export type PresetAddress = {
  zipCode: string;
  address: string;
  addressLong: string;
  raw?: any;
};

export type ApplePayData = {
  nonce: string;
  type: string;
  processor: PaymentProcessor;
};

export type Appointment = {
  notes?: string;
  service?: string;
  length?: number;
  preferredGender?: GenderRequestedEnum;
  massageFor?: 'myself' | 'guest' | 'string'; // person ID
};

export type RequestTime = {
  start?: number;
  end?: number;
  startId?: number;
  endId?: number;
};

export type TimeType = {
  label: string;
  start: number;
  end: number;
  range?: 'morning' | 'afternoon' | 'evening';
};

export type RequestType = {
  id: string; // local id
  type: 'specific' | 'range' | 'availability' | null;
  date: string;
  times: Array<TimeType>;
};

export type RebookingDetails = {
  timeSelected?: ProviderAvailabilityType;
  provider: ProviderType;
  requests?: Array<RequestType>;
};

export type Plans = 'standard' | MembershipPlans.MemberPlan | MembershipPlans.MemberPlusPlan;

export type State = {
  presetAddress?: PresetAddress;
  applePay?: ApplePayData;
  presetModality?: ServiceIds;
  servicePageSelection?: 'Massage' | 'Stretch';
  flowInitiatedBy?: string;
  zipCode?: string;
  howManyPeople: number;
  peopleType?: BookingCategoryEnum;
  currentAppointmentIndex: number;
  openToBackToBack: boolean;
  openToBackToBackGender?: GenderRequestedEnum;
  appointments: Appointment[];
  bookingAddress?: AddressType;
  shippingAddress?: AddressType;
  requestTime: RequestTime;
  rebookingDetails?: RebookingDetails;
  email?: string;
  agreeToTerms: boolean;
  allowVa: boolean;
  selectedPlan?: Plans;
  paymentMethodId?: string;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  presetAddress: null,
  applePay: null,
  presetModality: null,
  servicePageSelection: null,
  flowInitiatedBy: null,
  zipCode: '10005',
  howManyPeople: 1,
  peopleType: null,
  currentAppointmentIndex: 0,
  openToBackToBack: false,
  openToBackToBackGender: null,
  appointments: [],
  bookingAddress: null,
  shippingAddress: null,
  requestTime: {
    start: null,
    end: null,
    startId: null,
    endId: null,
  },
  rebookingDetails: null,
  email: null,
  agreeToTerms: false,
  allowVa: false,
  selectedPlan: null,
  paymentMethodId: null,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const personalWellnessFlowSlice = createSlice({
  name: 'personalWellnessFlowSlice',
  initialState,
  reducers: {
    userInitiatedFlow(state, action: PayloadAction<string>) {
      const userId = action.payload;

      if (userId === null || (state.flowInitiatedBy && userId !== state.flowInitiatedBy)) {
        state.bookingAddress = null;
        state.shippingAddress = null;
        state.paymentMethodId = null;
        state.presetAddress = null;
        state.applePay = null;
        state.rebookingDetails = null;
        state.requestTime = null;
        state.email = null;
        state.agreeToTerms = null;
        deleteCrossParams(constants.CROSS_PARAMS.PERSONAL_WELLNESS_FLOW);

        if (state.appointments?.find((a) => a.massageFor !== 'myself')) {
          state.currentAppointmentIndex = 0;
          state.appointments = [];
        }
      }

      state.flowInitiatedBy = userId;
    },
    addressPresetted(state, action: PayloadAction<PresetAddress>) {
      state.presetAddress = action.payload;
    },
    presetAddressCleared(state) {
      state.presetAddress = null;
      let routeStateFromLocalStorage: any = {};
      try {
        routeStateFromLocalStorage = getCrossParams(constants.CROSS_PARAMS.PERSONAL_WELLNESS_FLOW);
        if (routeStateFromLocalStorage?.presetAddress) {
          delete routeStateFromLocalStorage.presetAddress;
        }
        setCrossParams(constants.CROSS_PARAMS.PERSONAL_WELLNESS_FLOW, routeStateFromLocalStorage);
      } catch (e) {
        console.error(e);
      }
    },
    applePayConfigured(state, action: PayloadAction<ApplePayData>) {
      state.applePay = action.payload;
    },
    applePayCleared(state) {
      state.applePay = null;
    },
    modalityPresetted(state, action: PayloadAction<ServiceIds>) {
      state.presetModality = action.payload;
    },
    presetModalityCleared(state) {
      state.presetModality = null;
      let routeStateFromLocalStorage: any = {};
      try {
        routeStateFromLocalStorage = getCrossParams(constants.CROSS_PARAMS.PERSONAL_WELLNESS_FLOW);
        if (routeStateFromLocalStorage?.presetModality) {
          delete routeStateFromLocalStorage.presetModality;
        }
        setCrossParams(constants.CROSS_PARAMS.PERSONAL_WELLNESS_FLOW, routeStateFromLocalStorage);
      } catch (e) {
        console.error(e);
      }
    },
    serviceUpdated(state, action: PayloadAction<'Massage' | 'Stretch'>) {
      state.servicePageSelection = action.payload;
    },
    serviceCleared(state) {
      state.servicePageSelection = null;
    },
    userCreatedAccountInFlow(state, action: PayloadAction<string>) {
      state.flowInitiatedBy = action.payload;
    },
    userEnteredEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    userAnsweredToTerms(state, action: PayloadAction<boolean>) {
      state.agreeToTerms = action.payload;
    },
    zipUpdated(state, action: PayloadAction<string | null>) {
      state.zipCode = action.payload;
    },
    zipCleared(state) {
      state.zipCode = null;
    },
    amountOfPeopleUpdated(state, action: PayloadAction<number>) {
      state.howManyPeople = action.payload;
    },
    bookingCategoryUpdated(state, action: PayloadAction<BookingCategoryEnum>) {
      state.peopleType = action.payload;
    },
    currentAppointmentIncremented(state) {
      state.currentAppointmentIndex++;
    },
    currentAppointmentDecremented(state) {
      state.currentAppointmentIndex--;
    },
    currentAppointmentResetted(state) {
      state.currentAppointmentIndex = 0;
    },
    currentAppointmentUpdated(state, action: PayloadAction<Partial<Appointment>>) {
      const appt = state.appointments?.[state?.currentAppointmentIndex];
      const currApptIndex = state.currentAppointmentIndex;
      const updatedAppts = [...(state.appointments || [])];

      updatedAppts[currApptIndex] = {
        ...appt,
        ...action.payload,
      };

      state.appointments = updatedAppts;
    },
    appointmentsResetted(state) {
      state.howManyPeople = 1;
      state.peopleType = null;
      state.appointments = [];
      state.currentAppointmentIndex = 0;
    },
    startedNewBookingWithDifferentZip(state, action: PayloadAction<string>) {
      state.servicePageSelection = null;
      state.zipCode = action.payload;
      state.appointments = [...state.appointments].map((a) => {
        a.service = null;
        return a;
      });
    },
    appointmentsPresettedUsingPreviousBooking(state, action: PayloadAction<BookingType>) {
      const previousBooking = action.payload;
      const allPrevAppts = previousBooking?.appointments || [];

      if (previousBooking) {
        if (previousBooking.location?.zipCode) {
          state.zipCode = previousBooking.location.zipCode;
        }
        if (previousBooking.category === BookingCategoryEnum.Single) {
          state.peopleType = null;
          let updatedAppts: Appointment[] = [];
          // filtering out previous stretching data if service selected is Massage
          if (state.servicePageSelection === 'Massage') {
            updatedAppts = allPrevAppts.map(({ length, therapistGenderRequested, service }) => {
              const updatedAppt = {
                length,
                preferredGender: therapistGenderRequested,
                service: service.id,
                massageFor: null,
              };

              if (updatedAppt.service === ServiceIds.Stretch) {
                updatedAppt.service = null;
                updatedAppt.length = null;
              }
              if (![60, 75, 90].includes(updatedAppt.length)) {
                updatedAppt.length = null;
              }
              return updatedAppt;
            });
          }
          state.appointments = updatedAppts;
        }
      }
    },
    openToB2BUpdated(state, action: PayloadAction<GenderRequestedEnum>) {
      const genderFallback = action.payload;
      if (genderFallback) {
        state.openToBackToBack = true;
        state.openToBackToBackGender = genderFallback;
      } else {
        state.openToBackToBack = false;
      }
    },
    bookingAddressSelected(state, action: PayloadAction<AddressType | null>) {
      if (state.bookingAddress && state.bookingAddress.id !== action.payload?.id) {
        state.requestTime = null;
      }
      state.bookingAddress = action.payload;
    },
    bookingAddressCleared(state) {
      state.bookingAddress = null;
      state.requestTime = null;
    },
    shippingAddressSelected(state, action: PayloadAction<AddressType | null>) {
      state.shippingAddress = action.payload;
    },
    shippingAddressCleared(state) {
      state.shippingAddress = null;
    },
    paymentMethodIdSelected(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    paymentMethodIdCleared(state) {
      state.paymentMethodId = null;
    },
    requestTimeSelected(state, action: PayloadAction<Partial<RequestTime>>) {
      state.requestTime = action.payload;
      state.rebookingDetails = null;
    },
    requestTimeCleared(state) {
      state.requestTime = {
        start: null,
        end: null,
        startId: null,
        endId: null,
      };
    },
    rebookingDetailsUpdated(state, action: PayloadAction<RebookingDetails>) {
      state.rebookingDetails = action.payload;
      state.requestTime = null;
    },
    rebookingDetailsCleared(state) {
      state.rebookingDetails = null;
    },
    vaAllowed(state, action: PayloadAction<boolean>) {
      state.allowVa = action.payload;
    },
    selectedPlanUpdated(state, action: PayloadAction<Plans>) {
      state.selectedPlan = action.payload;
    },
    bookingResetted() {
      return { ...initialState };
    },
  },
});

export const personalWellnessFlowReducer = persistReducer<State>(personalWellnessFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => (!getTokenCookie() && os.flowInitiatedBy ? { ...initialState } : os)
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[personalWellnessFlowSlice.name] as State;

const generateBookingData = (state: RootState) => {
  const {
    applePay,
    peopleType,
    bookingAddress,
    shippingAddress,
    rebookingDetails,
    appointments,
    selectedPlan,
    requestTime,
    paymentMethodId,
    openToBackToBack,
    openToBackToBackGender,
  } = getSliceState(state);
  const category = peopleType || BookingCategoryEnum.Single;

  const bookingData: BookingConfigurationType = {
    category,
    [category]: true,
    appointments: (appointments || []).map((appointment) => {
      const { length, preferredGender: therapist_gender_requested, service, massageFor, notes } = appointment;
      const apptData: any = {
        length,
        therapist_gender_requested,
        service: { id: service },
        notes,
      };

      if (rebookingDetails?.provider?.id) apptData.therapist = { id: rebookingDetails.provider.id };
      if (massageFor !== 'myself') {
        apptData.customer = massageFor === 'guest' ? { guest: 1 } : { person_id: massageFor };
      }

      return apptData;
    }),
    ...(bookingAddress
      ? {
          location: { id: bookingAddress.id },
        }
      : {}),
    ...(shippingAddress
      ? {
          shipping_address_id: shippingAddress.id,
        }
      : {}),
    ...(selectedPlan !== 'standard'
      ? { chosen_plan: selectedPlan === MembershipPlans.MemberPlusPlan ? 'annual' : 'month_to_month' }
      : {}),
    ...(applePay
      ? applePay.processor === PaymentProcessor.Fortis
        ? {
            wallet_data: applePay.nonce,
            wallet_provider: 'apple_pay',
          }
        : {
            payment_method_nonce: applePay.nonce,
            payment_method_type: applePay.type,
          }
      : {}),
    ...(!applePay && paymentMethodId ? { card_id: paymentMethodId } : {}),
    ...(openToBackToBack
      ? {
          open_to_back_to_back: openToBackToBack,
          open_to_back_to_back_gender: openToBackToBackGender,
        }
      : {}),
  };

  // -----

  if (rebookingDetails) {
    if (rebookingDetails.timeSelected) {
      bookingData.request_times = [
        {
          start: rebookingDetails.timeSelected.id,
          end: rebookingDetails.timeSelected.id,
        },
      ];
    } else if (rebookingDetails.requests) {
      bookingData.rebooking_flow = 1;
      const allRequestedTimes = [];
      rebookingDetails.requests.forEach((r) => {
        r.times?.forEach((t) => {
          allRequestedTimes.push({
            start: t.start,
            end: t.end,
          });
        });
      });
      bookingData.request_times = allRequestedTimes;
    }
  } else if (requestTime) {
    bookingData.request_times = [
      {
        start: requestTime.startId + '',
        end: requestTime.endId + '',
      },
    ];
  }

  return bookingData;
};

export const personalWellnessFlowSelectors = {
  selectPresetAddress: (state) => getSliceState(state).presetAddress,
  selectApplePay: (state) => getSliceState(state).applePay,
  selectPresetModality: (state) => getSliceState(state).presetModality,
  selectService: (state) => getSliceState(state).servicePageSelection,
  selectFlowInitiatedBy: (state) => getSliceState(state).flowInitiatedBy,
  selectZipCode: (state) => getSliceState(state).zipCode,
  selectAmountOfPeople: (state) => getSliceState(state).howManyPeople,
  selectBookingCategory: (state) => getSliceState(state).peopleType,
  selectCurrentAppointmentIndex: (state) => getSliceState(state).currentAppointmentIndex,
  selectCurrentAppointment: (state) => {
    const { appointments = [], currentAppointmentIndex } = getSliceState(state);
    return appointments[currentAppointmentIndex];
  },
  selectIsStretch: (state) => getSliceState(state).servicePageSelection === 'Stretch',
  selectOpenToB2B: (state) => getSliceState(state).openToBackToBack,
  selectB2BFallbackGender: (state) => getSliceState(state).openToBackToBackGender,
  selectAppointments: (state) => getSliceState(state).appointments,
  selectBookingAddress: (state) => getSliceState(state).bookingAddress,
  selectShippingAddress: (state) => getSliceState(state).shippingAddress,
  selectRequestTime: (state) => getSliceState(state).requestTime,
  selectRebookingDetails: (state) => getSliceState(state).rebookingDetails,
  selectEmail: (state) => getSliceState(state).email,
  selectTermsAgreeance: (state) => getSliceState(state).agreeToTerms,
  selectVaAllowed: (state) => getSliceState(state).allowVa,
  selectPlan: (state) => getSliceState(state).selectedPlan,
  selectPaymentMethodId: (state) => getSliceState(state).paymentMethodId,
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectorsdefined within the slice itself
   **/
  selectTotalAppointmentLength: (state, adjustStep?: number) => {
    const { appointments = [], peopleType } = getSliceState(state);
    const apptLength =
      peopleType === BookingCategoryEnum.Couples
        ? appointments[0].length
        : (appointments as Appointment[]).reduce((total, appointment) => total + appointment.length, 0);
    if (adjustStep) {
      return apptLength + (apptLength % adjustStep);
    }
    return adjustStep ? apptLength + (apptLength % adjustStep) : apptLength;
  },
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectorsdefined within the slice itself
   **/
  selectIsStepAvailable: (state, step: Steps) => {
    const {
      appointments = [],
      servicePageSelection,
      selectedPlan,
      howManyPeople,
      bookingAddress,
      requestTime,
      rebookingDetails,
    } = getSliceState(state);
    const isAuthenticated = state[authSlice.name].authenticated as boolean;
    const isDetailsAvailable = !!servicePageSelection;

    let areAllDetailsFilled = true;
    appointments.forEach((apt) => {
      const { length, service, preferredGender, massageFor } = apt;
      if (!length || !service || !preferredGender || !massageFor) {
        areAllDetailsFilled = false;
      }
    });

    const isLocationAvailable =
      isDetailsAvailable && isAuthenticated && appointments?.length >= Number(howManyPeople) && areAllDetailsFilled;
    const isTimeAvailable = isLocationAvailable && bookingAddress?.id;
    const isPricingAvailable =
      isTimeAvailable &&
      ((requestTime?.startId && requestTime?.endId && requestTime?.start && requestTime?.end) ||
        (rebookingDetails &&
          ((rebookingDetails.provider && rebookingDetails.timeSelected) ||
            (rebookingDetails.provider && rebookingDetails.requests && rebookingDetails.requests.length > 0))));
    const isSummaryAvailable = isPricingAvailable && !!selectedPlan;
    const isFinishAvailable = isSummaryAvailable;

    return {
      [Steps.Details]: isDetailsAvailable,
      [Steps.Location]: isLocationAvailable,
      [Steps.Time]: isTimeAvailable,
      [Steps.Pricing]: isPricingAvailable,
      [Steps.Summary]: isSummaryAvailable,
      [Steps.Finish]: isFinishAvailable,
    }[step];
  },
  selectApiBookingData: (state) => {
    return generateBookingData(state);
  },
  selectBookingCreationPayload: (state, options?: { forceFailPreAuth?: boolean }): BookingCreationPayloadType => {
    const { forceFailPreAuth } = options || {};
    const { selectedPlan } = getSliceState(state);
    return {
      booking: generateBookingData(state),
      pre_auth_supported: true,
      ...(forceFailPreAuth && helpers.onLowerEnvironment() ? { dev_only_override_auth_amount: 2001 } : {}),
      ...(selectedPlan !== 'standard' ? { sign_up_zeelot: true } : {}),
    };
  },
  selectAmplitudeEventData: (state) => {
    const { appointments, peopleType, howManyPeople } = getSliceState(state);
    const firstAppt = appointments?.[0];
    const providerLayout =
      peopleType === BookingCategoryEnum.Couples
        ? 'Couples'
        : peopleType === BookingCategoryEnum.BackToBack
        ? 'Back to Back'
        : 'Single';
    return {
      modality: SERVICES_BY_ID[firstAppt?.service]?.title,
      duration: `${firstAppt?.length}m`,
      provider_layout: providerLayout,
      num_sessions: howManyPeople,
    };
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const usePersonalWellnessFlowSlice = () => {
  return useSliceWrapper(personalWellnessFlowSlice, personalWellnessFlowSelectors);
};
