import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import { createTransform } from 'redux-persist';
import { getTokenCookie } from 'redux/apis/OG/auth';
import { GiftPayloadType } from 'redux/apis/OG/user/types';
import { RootState } from 'redux/store';

import { useSliceWrapper } from '@zeel-dev/zeel-ui';

import { PaymentProcessor } from '../../apis/OG/payment';
import persistReducer from '../../persistReducer';

/**------------------------------------------------------------------**
 *                              Types                                 *
 **------------------------------------------------------------------**/

export enum Steps {
  Type = 'type',
  Style = 'style',
  Details = 'details',
  Checkout = 'checkout',
}

export type PresetType = number;

export type ApplePayData = {
  nonce: string;
  type: string;
  processor: PaymentProcessor;
};

export type GiftLocation = {
  stateId: any;
  regionId?: any;
};

export type GiftType = {
  typeId: any; // 1-single, 2-couple, 3-membership, 4-amount, 6-package
  productId?: any;
  customAmount?: any;
  isZeelotGift?: boolean;
};

export type GiftStyle = {
  occasionId: any;
  designId: any;
  message?: string;
};

export type GiftDetails = {
  delivery: string; // email | print
  recipientFirstName: string;
  recipientLastName: string;
  recipientEmail: string;
  firstName: string;
  lastName: string;
  email: string;
  date: string; // either date or 'now'
};

export type State = {
  flowInitiatedBy?: string;
  location?: GiftLocation;
  presetType?: PresetType;
  type?: GiftType;
  style?: GiftStyle;
  details?: GiftDetails;
  paymentMethodId: string;
  applePay?: ApplePayData;
};

/**------------------------------------------------------------------**
 *                           Initial State                            *
 **------------------------------------------------------------------**/

const initialState: State = {
  flowInitiatedBy: null,
  location: null,
  presetType: null,
  type: null,
  style: null,
  details: null,
  paymentMethodId: null,
  applePay: null,
};

/**------------------------------------------------------------------**
 *                              Slice                                 *
 **------------------------------------------------------------------**/

export const giftFlowSlice = createSlice({
  name: 'giftFlowSlice',
  initialState,
  reducers: {
    userInitiatedFlow(state, action: PayloadAction<string>) {
      const userId = action.payload;

      if (userId === null || (state.flowInitiatedBy && userId !== state.flowInitiatedBy)) {
        state.paymentMethodId = null;
        state.applePay = null;
        state.details = null;
      }

      state.flowInitiatedBy = userId;
    },
    locationUpdated(state, action: PayloadAction<GiftLocation>) {
      state.location = action.payload;
    },
    presetTypeUpdated(state, action: PayloadAction<PresetType>) {
      state.presetType = action.payload;
    },
    typeUpdated(state, action: PayloadAction<GiftType>) {
      state.type = action.payload;
    },
    styleUpdated(state, action: PayloadAction<GiftStyle>) {
      state.style = action.payload;
    },
    detailsUpdated(state, action: PayloadAction<GiftDetails>) {
      state.details = action.payload;
    },
    paymentMethodIdSelected(state, action: PayloadAction<string>) {
      state.paymentMethodId = action.payload;
    },
    paymentMethodIdCleared(state) {
      state.paymentMethodId = null;
    },
    applePayConfigured(state, action: PayloadAction<ApplePayData>) {
      state.applePay = action.payload;
    },
    applePayCleared(state) {
      state.applePay = null;
    },
    flowCompleted() {
      return { ...initialState };
    },
  },
});

export const giftFlowReducer = persistReducer<State>(giftFlowSlice, {
  transforms: [
    createTransform(
      (is) => is,
      (os) => (!getTokenCookie() && os.flowInitiatedBy ? { ...initialState } : os)
    ),
  ],
});

/**------------------------------------------------------------------**
 *                            Selectors                               *
 **------------------------------------------------------------------**/

const getSliceState = (state: RootState): State => state[giftFlowSlice.name] as State;

const generateGiftPayload = (state: RootState) => {
  const { applePay, paymentMethodId, details, style, type } = getSliceState(state);
  const { productId, customAmount, isZeelotGift } = type || {};
  const { delivery, recipientFirstName, recipientLastName, recipientEmail, firstName, lastName, email, date } =
    details || {};
  const { designId, message } = style || {};

  const giftPayload: GiftPayloadType = {
    message,
    from_name: `${firstName} ${lastName}`,
    email,
    to_name: `${recipientFirstName} ${recipientLastName}`,
    to_email: recipientEmail,
    dispatch_mode: date === 'now' ? 'now' : 'select_date',
    download_pdf: delivery === 'print',
    giftcard_design_id: designId,
    ...(productId ? { product_id: productId } : customAmount ? { amount: customAmount } : {}),
    ...(date && date !== 'now' ? { dispatch_date: moment(date)?.format('MM/DD/YYYY') } : {}),
    ...(isZeelotGift ? { has_table: false, signature: true } : {}),
    ...(applePay
      ? applePay.processor === PaymentProcessor.Fortis
        ? {
            wallet_data: applePay.nonce,
            wallet_provider: 'apple_pay',
          }
        : {
            payment_method_nonce: applePay.nonce,
            payment_method_type: applePay.type,
          }
      : { payment_profile_id: paymentMethodId }),
  };

  return giftPayload;
};

export const giftFlowSelectors = {
  selectLocation: (state) => getSliceState(state).location,
  selectPresetType: (state) => getSliceState(state).presetType,
  selectType: (state) => getSliceState(state).type,
  selectStyle: (state) => getSliceState(state).style,
  selectDetails: (state) => getSliceState(state).details,
  selectPaymentMethodId: (state) => getSliceState(state).paymentMethodId,
  selectApplePay: (state) => getSliceState(state).applePay,
  selectGiftPayload: (state) => generateGiftPayload(state),
  /**
   * Should be memoized, but will wait for redux-toolkit v2.0.0
   * for selectorsdefined within the slice itself
   **/
  selectIsStepAvailable: (state, step: Steps) => {
    const { location, type, style, details } = getSliceState(state);

    const giftTypeComplete = location?.stateId && type?.typeId && (type?.productId || type?.customAmount);
    const giftStyleComplete = style?.designId && style?.occasionId;
    const giftDetailsComplete =
      !!details?.recipientFirstName &&
      !!details?.recipientLastName &&
      (!!details?.recipientEmail || details?.delivery === 'print') &&
      !!details?.firstName &&
      !!details?.lastName &&
      !!details?.email &&
      !!details?.date;

    const isTypeAvailable = true;
    const isStyleAvailable = isTypeAvailable && giftTypeComplete;
    const isDetailsAvailable = isStyleAvailable && giftStyleComplete;
    const isCheckoutAvailable = isDetailsAvailable && giftDetailsComplete;

    return {
      [Steps.Type]: isTypeAvailable,
      [Steps.Style]: isStyleAvailable,
      [Steps.Details]: isDetailsAvailable,
      [Steps.Checkout]: isCheckoutAvailable,
    }[step];
  },
};

/**------------------------------------------------------------------**
 *                              Hook                                  *
 **------------------------------------------------------------------**/

export const useGiftFlowSlice = () => {
  return useSliceWrapper(giftFlowSlice, giftFlowSelectors);
};
